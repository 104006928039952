import React, { useState, useEffect, useRef } from "react";
import { FaWallet } from "react-icons/fa6";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { FaUser } from "react-icons/fa";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import whatsappicon from "../assets/images/whatsappicon.png";

import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Carousel } from "react-bootstrap";
import { fetchwalletamount } from "../Page/common";
import WalletAmount from "../Layout/WalletAmount";
import { CiLogout } from "react-icons/ci";
import { VscAccount } from "react-icons/vsc";
import { BsFileEarmarkBreakFill, BsQuestionCircle } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { FaMobileButton } from "react-icons/fa6";
import Slider from "react-slick";
import Logo from "../assets/images/logosec.png";
import "../Layout/Sidebar.scss";

import { MdSportsCricket } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import Cricketimage from "../assets/images/cricketicon.png";
import { ImVolumeHigh } from "react-icons/im";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
export default function Topnavbar() {
  // const [mobileNumber, setMobileNumber] = useState("");
  const token = localStorage.getItem("tokent");

  const [walletAmount, setWalletAmount] = useState(null);
  const username = localStorage.getItem("username");
  const userid = localStorage.getItem("userid");
  const phone_number = localStorage.getItem("phone_number");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [depositshow, setDepositshow] = useState(false);
  const navigate = useNavigate();

  const [Match, setMatch] = useState();
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputValuedeposit, setInputValuedeposit] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const depositClose = () => setDepositshow(false);
  const depositShow = () => setDepositshow(true);

  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");

  const [inputError, setInputError] = useState(false);
  const [bankNameError, setBankNameError] = useState(false);
  const [accountHolderNameError, setAccountHolderNameError] = useState(false);
  const [accountNumberError, setAccountNumberError] = useState(false);
  const [ifscCodeError, setIfscCodeError] = useState(false);

  const [expamount, setexpamount] = useState(null);
  const [show1, setShow1] = useState(false);
  const [whatsapp, setwhatsapp] = useState([]);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${process.env.REACT_APP_API_URL}general-setting`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const data = await response.json();
      // setMobileNumber(data.data.mobile);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    fetchGenralSetting();
  }, []);
  const fetchGenralSetting = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      "Content-Type": "application/json",
    };

    fetch(`${process.env.REACT_APP_API_URL}normal-setting`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
     
        setwhatsapp("https://wa.me/+" + data.data.mobile);
      
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  const [profile, setProfile] = useState(false);
  const logout = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out from the application.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log me out!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate("/");
        Swal.fire(
          "Logged Out!",
          "You have been successfully logged out.",
          "success"
        );
      }
    });
  };
  const profileRef = useRef(null);

  const profileshow = () => {
    setProfile(!profile);
  };

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setProfile(false);
    }
  };
  const handleButtonClick = (amount) => {
    setSelectedAmount(amount);
    setInputValue(amount);
  };

  const handleButtonClickdeposit = (amount) => {
    setSelectedAmount(amount);
    setInputValuedeposit(amount);
  };

  const handleWithdrawal = async () => {
    setIsButtonDisabled(true);

    try {
      const errors = [];
      setInputError(false);
      setBankNameError(false);
      setAccountHolderNameError(false);
      setAccountNumberError(false);
      setIfscCodeError(false);

      const amountPattern = /^\d+(\.\d{1,2})?$/;
      const bankNamePattern = /^[A-Za-z\s]+$/;
      const accountHolderNamePattern = /^[A-Za-z\s]+$/;
      const accountNumberPattern = /^\d+$/;
      // const ifscCodePattern = /^[A-Za-z]{4}\d{7}$/;
      const ifscCodePattern = /^[A-Za-z\s\d]+$/;

      if (!bankName.match(bankNamePattern)) {
        setBankNameError(true);
        errors.push("Please enter a valid bank name (letters and spaces only)");
      }

      if (!accountHolderName.match(accountHolderNamePattern)) {
        setAccountHolderNameError(true);
        errors.push(
          "Please enter a valid account holder name (letters and spaces only)"
        );
      }

      if (!accountNumber.match(accountNumberPattern)) {
        setAccountNumberError(true);
        errors.push("Please enter a valid account number (numbers only)");
      }

      if (!ifscCode.match(ifscCodePattern)) {
        setIfscCodeError(true);
        errors.push("Please enter a valid IFSC code (e.g., ABCD1234567)");
      }

      if (!inputValue) {
        setInputError(true);
        errors.push("Please enter a valid amount (e.g., 500 or 500.00)");
      }

      if (errors.length > 0) {
        errors.push(errors.join("\n"));
        return;
      }

      // if (errors.length > 0) {
      //   showError(errors.join('\n'));
      //   return;
      // }
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("amount", inputValue);
      formData.append("bank_name", bankName);
      formData.append("account_holder_name", accountHolderName);
      formData.append("account_number", accountNumber);
      formData.append("ifsc_code", ifscCode);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}withdrawal`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your Amount Withdraw Successfully",
          // timer: 2000,
          timerProgressBar: true,
        });
        setInputValue("");
        setBankName("");
        setAccountHolderName("");
        setAccountNumber("");
        setIfscCode("");
        //   setReloadPage(true);
        fetchwalletamount(setWalletAmount);

        handleClose();
      } else if (response.status === 422) {
        const data = await response.json();
        Swal.fire({
          icon: "error",
          title: "Minimum Withdraw 500",
          text: "Your account balance is insufficient to complete this transaction.",
          // timer: 3000,
        });
      } else {
        console.error("Withdrawal failed");
        Swal.fire({
          icon: "error",
          title: "Withdrawal Failed",
          text: "An error occurred during withdrawal",
          // timer: 3000,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred",
        // timer: 2000,
      });
    }
  };

  const handleDeposit = () => {
    if (inputValuedeposit) {
      Swal.fire({
        icon: "info",
        title: "Deposit Under Development",
        text: "This feature is currently under development. Please check back later.",
      });
      return;
    }
    if (inputValuedeposit) {
      const apiUrl = `${process.env.REACT_APP_API_URL}deposit.php?name=${username}&userid=${userid}&amount=${inputValuedeposit}&contact=${phone_number}`;
      window.location.href = apiUrl;
    } else {
      setError("Please enter an amount before depositing.");
    }
  };
  useEffect(() => {
    fetchwalletamount(setWalletAmount, setexpamount);
  }, []);
  const handleClick = () => {
    handleShow();
    handleClose1();
  };
  const handleClick1 = () => {
    Swal.fire({
      title: 'Deposit',
      text: 'डिपॉजिट सहायता के लिए, कृपया हमें WhatsApp पर संपर्क करें।',
      icon: 'info',
  
      confirmButtonText: `<img src="${whatsappicon}" alt="WhatsApp" style="width: 20px; height: 20px; vertical-align: middle;"/> WhatsApp पर जाएं`,


      cancelButtonText: 'रद्द करें',
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-confirm-button', 
        cancelButton: 'custom-cancel-button' 
    }
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(whatsapp, '_blank'); 
      }
    });

  };
  const style = document.createElement('style');
style.innerHTML = `
.custom-confirm-button {
    background-color: #04533a !important; 
    border: none !important;
}
`;
document.head.appendChild(style);
  var settings = {
    dots: false,
    infinite: true, // You might want to enable infinite scrolling
    arrows: false,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
  };

  const [seriesData, setSeriesData] = useState([]);
  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Date(dateTimeString).toLocaleString(
      undefined,
      options
    );
    return formattedDate.replace(/\b(\d{1,2}:\d{2})\s+(AM)\b/i, "$1 PM");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    axiosInstance
      // .get(`https://leobook.in/Admin/api/v1/series-ids?sport_id=${sportID}`)
      .get(`${process.env.REACT_APP_API_URL}all-match-data?sport_id=4`)
      .then((response) => {
        const matches = response.data.data.matches;

        // Create a new array with only the desired fields
        const seriesData = matches.flatMap((match) =>
          match.series.map((seriesItem) => ({
            name: seriesItem.name,
            date_time: seriesItem.date_time,
          }))
        );
        setMatch(seriesData);
        console.warn("Filtered Series Data", seriesData);
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const [cricketData, setCricketData] = useState([]);
  const [tennisData, setTennisData] = useState([]);
  const [footballData, setFootballData] = useState([]);
  const [games, setGames] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    fetch(`${process.env.REACT_APP_API_URL}games-types`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setGames(data.data.games);
      })
      .catch((error) => console.error(error));
  }, []);

  const fetchSportData = async (sportId, setData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}match-list?sport_id=${sportId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setData(data.data.matches);
    } catch (error) {
      console.error("Error fetching sport data:", error);
    }
  };

  useEffect(() => {
    fetchSportData(4, setCricketData);
    fetchSportData(2, setTennisData);
    fetchSportData(1, setFootballData);
  }, []);
  const handleSeriesClick = (seriesId) => {
    // Store seriesId in local storage
    localStorage.setItem("selectedSeriesId", seriesId);
  };
  const handleSeriesClick1 = () => {
    navigate("/Home");
    localStorage.setItem("home", 1);
    localStorage.removeItem("selectedSeriesId");
  };
  const renderSportItems = (data, sportId) => {
    return data.map((element) => (
      <li key={element.id}>
        <Link
          to={{
            pathname: "/index",
            search: `?sportId=${sportId}&seriesId=${element.series_id}`,
          }}
          onClick={() => handleSeriesClick(element.series_id)}
          className="d-flex gap-1"
        >
          <img src={Cricketimage} width={20} height={20} />
          <span className="text-light mx-1 elementname">{element.name}</span>
        </Link>
      </li>
    ));
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  useEffect(() => {
    // Add or remove class on body when sidebar visibility changes
    if (isSidebarVisible) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("sidebar-open");
    };
  }, [isSidebarVisible]);
  const dropdownRef = useRef(null);

  // Function to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfile(false); // Close dropdown
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownMenuRef = useRef(null);

  const toggleDropdownVisibility = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdownOnOutsideClick = (event) => {
    if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const performLogout = () => {
    // Your logout logic here
    console.log("User logged out");
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdownOnOutsideClick);
    return () => {
      document.removeEventListener('mousedown', closeDropdownOnOutsideClick);
    };
  }, []);

  return (
    <div className="header_top_new">
      <div className="mobilemenu">
        <div className="d-flex justify-content-between margin-top-bottom-topnavbar align-items-center">
          <div className="d-flex gap-2">
            <button onClick={toggleSidebar} className="toggle-button">
              {isSidebarVisible ? (
                <GiHamburgerMenu />
              ) : (
                <IoCloseSharp className="closebtn_toggle" />
              )}
            </button>
          </div>
          <div>
            <Link to="/Home">
              <div className="logo_mobile">
                <img src={Logo} alt="Logo" />
              </div>
            </Link>
          </div>
          <div className="d-flex gap-2 align-items-start">
            {/* <div className="button" onClick={handleShow1}>
              <button className="btn btn_Transaction">Transaction</button>
            </div> */}
            <div className="text-center">
              <button
                className="btn  text-white bg-color-section "
                onClick={handleClick1}
              >
                Deposit
              </button>
              <button
                className="btn text-white bg-color-section"
                onClick={handleClick}
              >
                Withdrawal
              </button>
            </div>
            <div>
              <div className="wallet_details">
                <div className="d-flex align-items-center gap-2">
                  <div className="icon">
                    <FaWallet />
                  </div>
                  <div className="pts">
                    <WalletAmount />
                  </div>
                  {/* <div className="button" onClick={handleShow1}>
                  <button className="btn btn_Transaction">Transaction</button>
                </div> */}
                </div>
              </div>
              <div className="loginId position-relative">
                <div
                  className="d-flex justify-content-end"
                  // onClick={profileshow}
                  onClick={toggleDropdownVisibility}            >
                  <div className="icon fauser">
                    <FaUser />
                  </div>
                  <div className="pts d-flex gap-2 align-items-center">
                    <p className="from-select">
                      {phone_number} <FaChevronDown />
                    </p>
                  </div>
                </div>
                {/* {profile && (
                  <div
                    className={`dropdown_profile ${profile ? "activenav" : ""}`}
                  >
                    <ul>
                      <li>
                        <Link to="/Accountstatement">
                          {" "}
                          <VscAccount /> Account Statements
                        </Link>
                      </li>
                      <li>
                        <Link to="/Bethistory">
                          {" "}
                          <BsQuestionCircle />Settled Bets 
                        </Link>
                      </li>
                      <li>
                        <Link to="/Unsettledbet">
                          <BsFileEarmarkBreakFill /> Unsettled Bets
                        </Link>
                      </li>
                     
                      <li>
                        <Link to="/Setbuttonvalue">
                          <FaMobileButton /> Set Button Value
                        </Link>
                      </li>
                      <li>
                        <Link onClick={logout}>
                          <CiLogout /> Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                )} */}
          {isOpen && (
        <div ref={dropdownMenuRef} className={`dropdown_profile ${isOpen ? "activenav" : ""}`}>
          <ul>
            <li>
              <Link to="/Accountstatement" onClick={() => setIsOpen(false)}>
                <VscAccount /> Account Statements
              </Link>
            </li>
            <li>
              <Link to="/Bethistory" onClick={() => setIsOpen(false)}>
                <BsQuestionCircle /> Settled Bets
              </Link>
            </li>
            <li>
              <Link to="/Unsettledbet" onClick={() => setIsOpen(false)}>
                <BsFileEarmarkBreakFill /> Unsettled Bets
              </Link>
            </li>
            <li>
              <Link to="/Setbuttonvalue" onClick={() => setIsOpen(false)}>
                <FaMobileButton /> Set Button Value
              </Link>
            </li>
            <li>
              <Link to="#" onClick={logout}>
                <CiLogout /> Logout
              </Link>
            </li>
          </ul>
        </div>
      )}
              </div>
            </div>
          </div>
        </div>
        <div className="news-bar">
          <div className="fixure-title speaker">
            <HiOutlineSpeakerWave className="shake" />
          </div>
          <marquee>
            Enjoy the Battle of TNPL &amp; GT20 Canada League and Win Instantly
            with Scratch Lottery!!!
          </marquee>
        </div>
        <div
          className={`overlay_screen  ${isSidebarVisible ? "open" : "closed"}`}
          onClick={toggleSidebar}
        ></div>
        <div
          className={`sidebar mobilesidebarmenu  ${
            isSidebarVisible ? "open" : "closed"
          }`}
          id="sidebar"
        >
          <Link to="/Home" onClick={handleSeriesClick1}>
            <div className="logo_web">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
          <div>
            <h3 className="heading_game">Racing Sport</h3>
            <div className="menusidebar">
              <ul className="menu">
                <li>
                  <Link
                    to="/Home"
                    onClick={handleSeriesClick1}
                    className="nav-link text-white d-flex gap-1 align-items-center"
                  >
                    <FaHome />
                    <span>Home</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Brodcast"
                    className="nav-link text-white d-flex gap-1 align-items-center"
                  >
                    <ImVolumeHigh />
                    <span>Brodcast</span>
                  </Link>
                </li>
                {games.map((item, index) => (
                  <li key={index}>
                    <div
                      onClick={() => toggleDropdown(index)}
                      className={`menubar_series ${
                        openDropdown === index ? "active" : ""
                      }`}
                    >
                      <span>
                        <MdSportsCricket /> {item.name}
                      </span>
                    </div>
                    {openDropdown === index && (
                      <ul className="submenu">
                        {item.id === 4
                          ? renderSportItems(cricketData, item.id)
                          : item.id === 2
                          ? renderSportItems(tennisData, item.id)
                          : renderSportItems(footballData, item.id)}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="Topnavbar text-white">
        <div className="upcoming-fixure heading_new d-flex align-items-center">
          <div class="fixure-title">Upcoming Fixure</div>
          <div className="fixure-box-container">
            <div className="vertical-slider-container">
              <Slider className="sliderbanner" {...settings}>
                {Match &&
                  Match.map((Match) => (
                    <React.Fragment>
                      <div className="d-flex width_match">
                        <Link to="/Bettingpage" className="text-white">
                          <div className="">
                            <div className="matchname"> {Match.name}</div>

                            {/* <small className=''> {Match.date_time}
                          </small>   */}
                          </div>
                        </Link>
                      </div>
                    </React.Fragment>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="news-bar">
          <div className="fixure-title speaker">
            <HiOutlineSpeakerWave className="shake" />
          </div>
          <marquee>
            Enjoy the Battle of TNPL &amp; GT20 Canada League and Win Instantly
            with Scratch Lottery!!!
          </marquee>
        </div>
        <div className="wallet_details">
          <div className="d-flex align-items-center gap-2">
            <div className="icon">
              <FaWallet />
            </div>
            <div className="pts">
              <WalletAmount />
              {/* <p>PTS : <strong>{walletAmount || 0}</strong></p>
              <p>EXP : <strong>{expamount || 0}</strong></p> */}
            </div>
            <div>
              <button
                className="btn text-white bg-color-section"
                onClick={handleClick}
              >
                Withdrawal
              </button>
              <button
                className="btn mx-1 text-white bg-color-section "
                onClick={handleClick1}
              >
                Deposit
              </button>
            </div>

            {/* <div className="button" onClick={handleShow1}>
              <button className="btn btn_Transaction">Transaction</button>
            </div> */}
          </div>
        </div>
        {/* <div  onClick={profileshow} className='overlay'></div> */}
        <div className="loginId position-relative">
          <div className="d-flex" onClick={profileshow}>
            <div className="icon">
              <FaUser className="user_design" />
            </div>
            <div className="pts d-flex gap-2 align-items-center">
              <p className="from-select">
                {phone_number} <FaChevronDown />
              </p>
            </div>
          </div>
          {profile && (
            <div
              ref={dropdownRef}
              className={`dropdown_profile ${profile ? "activenav" : ""}`}
            >
              <ul>
                <li>
                  <Link to="/Accountstatement">
                    {" "}
                    <VscAccount /> Account Statement
                  </Link>
                </li>
                <li>
                  <Link to="/Bethistory">
                    {" "}
                    <BsQuestionCircle /> Settled Bets
                  </Link>
                </li>
                <li>
                  <Link to="/Unsettledbet">
                    <BsFileEarmarkBreakFill /> Unsettled Bets
                  </Link>
                </li>
                {/* <li>
                                    <Link to="void:javascript(0);">Current Bets</Link>
                                </li>
                                <li>
                                    <Link to="void:javascript(0);">Casino Results</Link>
                                </li> */}
                <li>
                  <Link to="/Setbuttonvalue">
                    <FaMobileButton /> Set Button Value
                  </Link>
                </li>
                <li>
                  <Link onClick={logout}>
                    <CiLogout /> Logout
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{ background: "#04533a" }}>
            <Modal.Title className="text-light">
              Withdrawal <small>Min ₹500 /-</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: "#444", padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-12 mx-auto">
                  <div
                    className="  mb-4"
                    style={{ background: "#444", color: "white" }}
                  >
                    <form id="form-send-money" method="post">
                      <div className="mb-3">
                        <label for="youSend" className="form-label mb-0">
                          Amount
                        </label>
                        <div className="d-flex position-relative">
                          <span className="rupeesign">₹</span>
                          <input
                            type="number"
                            className={`form-control form-custum ${
                              inputError ? "is-invalid" : ""
                            }`}
                            id="youSend"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Amount"
                          />
                          {inputError && (
                            <div className="invalid-feedback">
                              Please enter a valid amount (e.g., 500 or 500.00)
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-wrap gap-3 mt-2 bg-btn">
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClick(500)}
                          >
                            500
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClick(600)}
                          >
                            600
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClick(700)}
                          >
                            700
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClick(800)}
                          >
                            800
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClick(900)}
                          >
                            900
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClick(1000)}
                          >
                            1000
                          </Button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Form.Group
                          md="4"
                          className="mb-2"
                          controlId="validationCustom01"
                        >
                          <Form.Label className="mb-0">Bank Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Bank Name"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            className={`form-control form-custum ${
                              bankNameError ? "is-invalid" : ""
                            }`}
                          />
                          {bankNameError && (
                            <div className="invalid-feedback">
                              Please enter a valid bank name (letters and spaces
                              only)
                            </div>
                          )}
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          md="4"
                          className="mb-2"
                          controlId="validationCustom01"
                        >
                          <Form.Label className="mb-0">
                            Account Holder Name
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Account Holder Name"
                            value={accountHolderName}
                            onChange={(e) =>
                              setAccountHolderName(e.target.value)
                            }
                            className={`form-control form-custum ${
                              accountHolderNameError ? "is-invalid" : ""
                            }`}
                          />
                          {accountHolderNameError && (
                            <div className="invalid-feedback">
                              Please enter a valid account holder name (letters
                              and spaces only)
                            </div>
                          )}
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          md="4"
                          className="mb-2"
                          controlId="validationCustom01"
                        >
                          <Form.Label className="mb-0">
                            Account Number
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Account Number"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            className={`form-control form-custum  ${
                              accountNumberError ? "is-invalid" : ""
                            }`}
                          />
                          {accountNumberError && (
                            <div className="invalid-feedback">
                              Please enter a valid account number (numbers only)
                            </div>
                          )}
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          md="4"
                          className="mb-2"
                          controlId="validationCustom01"
                        >
                          <Form.Label className="mb-0">IFSC Code</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="IFSC Code"
                            value={ifscCode}
                            onChange={(e) => setIfscCode(e.target.value)}
                            className={`form-control form-custum  ${
                              ifscCodeError ? "is-invalid" : ""
                            }`}
                          />
                          {ifscCodeError && (
                            <div className="invalid-feedback">
                              Please enter a valid IFSC code (e.g., ABCD1234567)
                            </div>
                          )}
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      {handleWithdrawal ? (
                        <div className="d-grid">
                          <button
                            type="button"
                            className="btn text-light  submitbtn"
                            style={{ background: "#04533a" }}
                            onClick={handleWithdrawal}
                            disabled={isButtonDisabled}
                          >
                            Submit
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* depoist */}
        <Modal show={depositshow} onHide={depositClose}>
          <Modal.Header
            closeButton
            className="text-light"
            style={{ background: "#04533a" }}
          >
            <Modal.Title>
              Deposit <small>Min ₹100/-</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: "#444", padding: "0px" }}>
            <div className="container">
              <div className="row ">
                <div className="col-md-12 col-12 mx-auto">
                  <form id="form-send-money" method="post">
                    <div
                      className="rounded mb-4"
                      style={{ background: "#444", color: "white" }}
                    >
                      <div className="mb-3">
                        <label for="youSend" className="form-label mb-0">
                          Amount
                        </label>
                        <div className="d-flex position-relative">
                          <span className="rupeesign">₹</span>
                          <input
                            type="number"
                            className="form-control form-custum "
                            data-bv-field="youSend"
                            id="youSend"
                            value={inputValuedeposit}
                            placeholder="Enter Amount"
                            onChange={(e) =>
                              setInputValuedeposit(e.target.value)
                            }
                            required
                          />
                        </div>
                        {error && <div className="text-danger">{error}</div>}
                        <div className="d-flex flex-wrap gap-3  mt-2">
                          <Button
                            className="btn-amount  bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClickdeposit(200)}
                          >
                            200
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClickdeposit(400)}
                          >
                            400
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClickdeposit(600)}
                          >
                            600
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClickdeposit(700)}
                          >
                            700
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClickdeposit(800)}
                          >
                            800
                          </Button>
                          <Button
                            className="btn-amount bg-btnmargin bg-color-section"
                            onClick={() => handleButtonClickdeposit(1000)}
                          >
                            1000
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn d-block w-100 submitbtn text-light"
                          style={{ background: "#04533a" }}
                          onClick={handleDeposit}
                        >
                          Deposit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
